import { gql } from '@apollo/client';

export const GET_ALL_SESSION_SETUPS = gql`
  query getAllSessionSetups($page: Int!, $search: String!) {
    _getAllSessionSetups(page: $page, search: $search) {
      sessionSetups {
        id
        title
        createdAt
        creator {
          firstName
          lastName
        }
        sessions {
          totalSessions
        }
      }
      totalSessionSetups
    }
  }
`;

export const GET_SESSION_SETUP_BY_ID = gql`
  query getSessionSetupById($sessionSetupId: ID!) {
    _getSessionSetupById(sessionSetupId: $sessionSetupId) {
      id
      title
      createdAt
      creator {
        id
        firstName
        lastName
      }
      sessions {
        sessions {
          id
          title
          participantCount
          pin
          creator {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
        }
        totalSessions
      }
    }
  }
`;
