import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
// data queries
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import tables from 'utils/tables.jsx';
import {
  GET_PURCHASE,
  REVOKE_PURCHASE,
  VERIFY_PURCHASE,
} from 'queries/purchase.js';
import ConfirmBtn from 'components/ConfirmBtn/ConfirmBtn.jsx';
import { Switch } from '@mui/material';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  fullWidth: { width: '100%' },
  comment: {
    whiteSpace: 'pre-wrap',
    padding: '1rem 2rem',
    borderRadius: '0.5rem',
    border: '1px solid #bfbfbf',
  },
  gappedCardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  inline: {
    'display': 'flex',
    'gap': '1rem',
    '& > *': {
      height: 'max-content',
    },
  },
};

const useStyles = makeStyles(styles);

export default function PurchaseView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const { data: { _getPurchase: data } = {}, refetch } = useQuery(
    GET_PURCHASE,
    { fetchPolicy: 'network-only', variables: { purchaseId: params.id } }
  );

  const [verifyPurchase] = useMutation(VERIFY_PURCHASE, {
    variables: { purchaseId: params.id },
    onCompleted: () => refetch(),
  });
  const [revokePurchase] = useMutation(REVOKE_PURCHASE, {
    variables: { purchaseId: params.id },
    onCompleted: () => refetch(),
  });

  if (!data) return null;

  return (
    <GridContainer className={classes.fullWidth}>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Info</h4>
          </CardHeader>
          <CardBody className={classes.gappedCardBody}>
            <tables.purchases
              data={[data]}
              cellClicked={() => navigate(`/admin/user/${data.user.id}`)}
            />
            <div className={classes.inline}>
              {data.pending && !data.revokedAt && (
                <ConfirmBtn text="VERIFY" onClick={() => verifyPurchase()} />
              )}
              {!data.revokedAt && (
                <ConfirmBtn text="REVOKE" onClick={() => revokePurchase()} />
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem className={classes.fullWidth}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Comment</h4>
          </CardHeader>
          <CardBody>
            <p className={classes.comment}>{data.comment}</p>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem className={classes.fullWidth}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Product</h4>
          </CardHeader>
          <CardBody>
            <tables.products
              data={[data.product]} // TODO
              cellClicked={() => navigate(`/admin/product/${data.product.id}`)}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
