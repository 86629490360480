import {
  defaultFont,
  grayColor,
} from '../../assets/jss/material-dashboard-react';

const footerStyle = {
  footer: {
    display: 'flex',
    justifyContent: 'center',
    bottom: '0',
    fontSize: '14px',
    borderTop: '1px solid ' + grayColor[11],
    padding: '15px 0',
    ...defaultFont,
  },
};
export default footerStyle;
