import React from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
// data hooks
import PageSelector from '../../components/PageSelector/PageSelector';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ALL_PRODUCTS } from 'queries/product.js';
import tables from 'utils/tables.jsx';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export default function ProductList() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const page = Number(params.page ?? 1);
  const search = params.search ?? '';
  const { data: { _getAllProducts: data } = {}, loading } = useQuery(
    GET_ALL_PRODUCTS,
    { fetchPolicy: 'cache-first', variables: { page, search } }
  );

  return (
    <>
      <PageSelector
        page={page}
        search={search}
        setPage={({ page, search }) =>
          navigate(`/admin/products/${page}/${search}`)
        }
        amount={data?._getAllProducts?.totalProducts || 0}
        loading={loading}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Product List</h4>
              <p className={classes.cardCategoryWhite}>
                All registered products
              </p>
            </CardHeader>
            <CardBody>
              <tables.products
                data={data?.products}
                cellClicked={(cellId) => navigate(`/admin/product/${cellId}`)}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <PageSelector
        page={page}
        search={search}
        setPage={({ page, search }) =>
          navigate(`/admin/products/${page}/${search}`)
        }
        amount={data?._getAllProducts?.totalProducts || 0}
        loading={loading}
      />
    </>
  );
}
