import React from 'react';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
// @mui/icons-material
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AccessTime from '@mui/icons-material/AccessTime';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader.js';
import CardIcon from '../../components/Card/CardIcon.js';
import CardFooter from '../../components/Card/CardFooter.js';

import styles from '../../assets/jss/material-dashboard-react/views/dashboardStyle.js';

import useDashboardData from '../../hooks/useDashboardData';
import DateChart from 'components/DateChart/DateChart.jsx';
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const dashboardData = useDashboardData();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <PeopleAltIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Users</p>
              <h3 className={classes.cardTitle}>
                {`${dashboardData.lastWeekUsers}/${dashboardData.totalUsers}`}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Users last week / Total</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <SportsEsportsIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Setups</p>
              <h3
                className={classes.cardTitle}
              >{`${dashboardData.lastWeekSessionSetups}/${dashboardData.totalSessionSetups}`}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Setups last Week / Total</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <PlayCircleFilledIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Participants</p>
              <h3
                className={classes.cardTitle}
              >{`${dashboardData.lastWeekParticipants}/${dashboardData.totalParticipants}`}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                Participants last Week / Total
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <LiveTvIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Sessions</p>
              <h3
                className={classes.cardTitle}
              >{`${dashboardData.lastWeekSessions}/${dashboardData.totalSessions}`}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Sessions last Week / Total</div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <DateChart title="users" data={dashboardData.usersCreatedEachDay} />
        <DateChart
          title="participants"
          data={dashboardData.participantsCreatedEachDay}
        />
        <DateChart
          title="setups"
          data={dashboardData.sessionSetupsCreatedEachDay}
        />
        <DateChart
          title="sessions"
          data={dashboardData.sessionsCreatedEachDay}
        />
      </GridContainer>
    </div>
  );
}
