import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import { CREATE_PUBLISHER } from 'queries/admin';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export default function CreatePublisher(props) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [createPublisher] = useMutation(CREATE_PUBLISHER, {
    variables: { name },
    onCompleted: (data) => {
      if (data?._createPublisher) props.refetch();
    },
  });

  const handleChange = (event) => {
    event.persist();
    setName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await createPublisher();
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Publisher name"
        onChange={handleChange}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={name.length < 1}
        className={classes.submit}
        value="test"
      >
        Create Publisher
      </Button>
    </form>
  );
}
