import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import {
  CHANGE_ORGANIZATION_GOOGLE_HOSTED_DOMAIN,
  CHANGE_ORGANIZATION_AZURE_AD_TENANT_ID,
} from 'queries/admin';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export const OrganizationOptions = ({
  orgId,
  refetch,
  hostedDomain,
  tenantID,
}) => {
  const classes = useStyles();
  const [newGoogleHostedDomain, setNewGoogleHostedDomain] =
    useState(hostedDomain);
  const [newAzureADTenantID, setNewAzureADTenantID] = useState(tenantID);
  const [googleButtonEnabled, setGoogleButtonEnabled] = useState(false);
  const [azureADButtonEnabled, setAzureADButtonEnabled] = useState(false);
  const [changeOrganizationGoogleHostedDomain] = useMutation(
    CHANGE_ORGANIZATION_GOOGLE_HOSTED_DOMAIN,
    {
      variables: {
        orgId,
        hd: newGoogleHostedDomain,
      },
      onCompleted: (data) => {
        if (data?._changeOrganizationGoogleHostedDomain) {
          refetch();
        }
      },
    }
  );
  const [changeOrganizationAzureADTenantID] = useMutation(
    CHANGE_ORGANIZATION_AZURE_AD_TENANT_ID,
    {
      variables: {
        orgId,
        tenantid: newAzureADTenantID,
      },
      onCompleted: (data) => {
        if (data?._changeOrganizationAzureADTenantID) {
          refetch();
        }
      },
    }
  );

  const handleHostedDomainChange = (event) => {
    event.persist();
    setNewGoogleHostedDomain(event.target.value);
    setGoogleButtonEnabled(
      event.target.value.length > 0 && event.target.value.includes('.')
    );
  };

  const handleTenantIDChange = (event) => {
    event.persist();
    setNewAzureADTenantID(event.target.value);
    setAzureADButtonEnabled(event.target.value.length > 0);
  };

  const handleSubmitHostedDomain = async (event) => {
    event.preventDefault();
    await changeOrganizationGoogleHostedDomain();
  };

  const handleSubmitTenantID = async (event) => {
    event.preventDefault();
    await changeOrganizationAzureADTenantID();
  };

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmitHostedDomain}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newHostedDomain"
          label={
            hostedDomain
              ? `Current Google Hosted Domain: ${hostedDomain}`
              : 'New Google Hosted Domain'
          }
          name="newHostedDomain"
          placeholder="New Google Hosted Domain"
          onChange={handleHostedDomainChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!googleButtonEnabled}
          className={classes.submit}
          value="Save"
        >
          Change Google Hosted Domain
        </Button>
      </form>
      <form className={classes.form} onSubmit={handleSubmitTenantID}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newTenantID"
          label={
            tenantID
              ? `Current Azure AD Tenant ID: ${tenantID}`
              : 'New Azure AD Tenant ID'
          }
          name="newTenantID"
          placeholder="New Azure AD Tenant ID"
          onChange={handleTenantIDChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!azureADButtonEnabled}
          className={classes.submit}
          value="Save"
        >
          Change Azure AD Tenant ID
        </Button>
      </form>
    </>
  );
};
