import Dashboard from '@mui/icons-material/Dashboard';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DashboardPage from './views/Dashboard/Dashboard';
import Login from './views/Login/Login';
import LoginCallback from './views/Login/LoginCallback';
import UserList from './views/UserList/UserList';
import UserView from './views/UserView/UserView';
import SessionList from './views/SessionList/SessionList';
import SessionView from './views/SessionView/SessionView';
import OrganizationList from './views/OrganizationList/OrganizationList';
import OrganizationView from './views/OrganizationView/OrganizationView';
import SessionSetupListView from './views/SessionSetupList/SessionSetupList';
import SessionSetupView from './views/SessionSetupView/SessionSetupView';
import {
  GridViewRounded,
  MenuBookRounded,
  StoreRounded,
} from '@mui/icons-material';
import PublisherList from 'views/PublisherList/PublisherList';
import PublisherView from 'views/PublisherView/PublisherView';
import ProductView from 'views/ProductView/ProductView';
import ProductList from 'views/ProductList/ProductList';
import ProductPurchaseList from 'views/ProductPurchaseList/ProductPurchaseList';
import PurchaseView from 'views/PurchaseView/PurchaseView';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    dashboard: true,
    icon: Dashboard,
    element: <DashboardPage />,
    layout: '/admin',
  },
  {
    path: '/login',
    name: 'Login',
    dashboard: false,
    element: <Login />,
    layout: '/admin',
  },
  {
    path: '/callback',
    name: 'Login Callback',
    dashboard: false,
    element: <LoginCallback />,
    layout: '/admin',
  },
  {
    path: '/users/:page?/:search?',
    name: 'Users',
    dashboard: true,
    icon: PeopleAltIcon,
    element: <UserList />,
    layout: '/admin',
  },
  {
    path: '/user/:id',
    name: 'User',
    dashboard: false,
    element: <UserView />,
    layout: '/admin',
  },
  {
    path: '/sessionSetups/:page?/:search?',
    name: 'Session Setups',
    dashboard: true,
    icon: SportsEsportsIcon,
    element: <SessionSetupListView />,
    layout: '/admin',
  },
  {
    path: '/sessionSetup/:id',
    name: 'Session Setup',
    dashboard: false,
    element: <SessionSetupView />,
    layout: '/admin',
  },
  {
    path: '/sessions/:page?/:search?',
    name: 'Sessions',
    dashboard: true,
    icon: LiveTvIcon,
    element: <SessionList />,
    layout: '/admin',
  },
  {
    path: '/session/:id',
    name: 'Session',
    dashboard: false,
    element: <SessionView />,
    layout: '/admin',
  },
  {
    path: '/organizations/:page?/:search?',
    name: 'Organizations',
    dashboard: true,
    icon: SportsEsportsIcon,
    element: <OrganizationList />,
    layout: '/admin',
  },
  {
    path: '/organization/:orgId',
    name: 'Organization',
    dashboard: false,
    element: <OrganizationView />,
    layout: '/admin',
  },
  {
    path: '/publishers/:page?/:search?',
    name: 'Publishers',
    dashboard: true,
    icon: MenuBookRounded,
    element: <PublisherList />,
    layout: '/admin',
  },
  {
    path: '/publisher/:publisherId',
    name: 'Publisher',
    dashboard: false,
    element: <PublisherView />,
    layout: '/admin',
  },
  {
    path: '/products/:page?/:search?',
    name: 'Products',
    dashboard: true,
    icon: GridViewRounded,
    element: <ProductList />,
    layout: '/admin',
  },
  {
    path: '/product/:productId',
    name: 'Product',
    dashboard: false,
    element: <ProductView />,
    layout: '/admin',
  },
  {
    path: '/product-purchases/:page?/:search?',
    name: 'Product Purchases',
    dashboard: true,
    icon: StoreRounded,
    element: <ProductPurchaseList />,
    layout: '/admin',
  },
  {
    path: '/purchase/:id',
    name: 'Purchase',
    dashboard: false,
    element: <PurchaseView />,
    layout: '/admin',
  },
];

export default routes;
