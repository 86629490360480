/*eslint-disable*/
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import styles from './footerStyle.js';

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <p>
        <span>
          Version {process.env.REACT_APP_VERSION} &copy;{' '}
          {1900 + new Date().getYear()}{' '}
          <a href="https://voxt.no" target="_blank" className={classes.a}>
            VOXT
          </a>
          , made with love for education and development
        </span>
      </p>
    </footer>
  );
}
