import { gql } from '@apollo/client';
import { productPurchaseInfo } from './purchase';

export const GET_PRODUCT = gql`
  query getProduct($productId: ID!) {
    getProduct(productId: $productId) {
      id
      title
      shared
      createdAt
      purchases {
        ${productPurchaseInfo}
      }
      revokedPurchases {
        ${productPurchaseInfo}
      }
      sessionSetups {
        id
        title
        createdAt
        creator {
          id
          firstName
          lastName
        }
        sessions {
          totalSessions
        }
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts($page: Int!, $search: String!) {
    _getAllProducts(page: $page, search: $search) {
      products {
        id
        title
        shared
        createdAt
        purchases {
          id
        }
        sessionSetups {
          id
        }
      }
      totalProducts
    }
  }
`;
