import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import IconButton from '@material-ui/core/IconButton';

// data hooks
import { UserActivation } from './UserActivation.js';
import { UserOptions } from './UserOptions.js';
import DateChart from 'components/DateChart/DateChart.jsx';
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { LaunchRounded } from '@mui/icons-material';
import { GET_USER_BY_ID } from 'queries/user.js';
import { useQuery } from '@apollo/client';
import tables from 'utils/tables.jsx';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  userFeatures: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  cardPublisher: {
    color: 'black',
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
};

const useStyles = makeStyles(styles);

export default function UserView(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [showUserOptions, setShowUserOptions] = useState(false);
  const { data: { _getUserById: data } = {}, refetch } = useQuery(
    GET_USER_BY_ID,
    { variables: { userId: params.id } }
  );

  const toggleUserOptions = () => {
    setShowUserOptions((previous) => !previous);
  };

  const buttonColor = showUserOptions ? 'primary' : 'default';

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Info</h4>
            <p className={classes.cardCategoryWhite}>Usage specific</p>
            <IconButton
              color={buttonColor}
              size="small"
              onClick={toggleUserOptions}
            >
              <SettingsApplicationsIcon />
            </IconButton>
            {showUserOptions ? (
              <UserOptions user={data} userId={params.id} refetch={refetch} />
            ) : null}
          </CardHeader>
          <CardBody>
            {data?.publishers && (
              <ul>
                <span style={{ fontVariant: 'small-caps' }}>publishers:</span>
                {data?.publishers.map((publisher) => (
                  <li key={publisher.id}>
                    <a
                      className={classes.cardPublisher}
                      href={`${process.env.REACT_APP_VOXT_ME}/publisher/${publisher.id}`}
                      target="_blank"
                    >
                      {publisher.name}
                      <LaunchRounded fontSize="inherit" />
                    </a>
                  </li>
                ))}
              </ul>
            )}
            <tables.users data={[data]} />
          </CardBody>
        </Card>
      </GridItem>

      <DateChart title="sessions by user" data={data?.sessionsCreatedEachDay} />
      <DateChart
        title="setups by user"
        data={data?.sessionSetupsCreatedEachDay}
      />

      <GridItem xs={12} sm={12} md={12}>
        <CollapsibleCard
          color="info"
          title="Session Setups"
          description="Self-created"
        >
          <tables.sessionSetups
            data={data?.sessionSetups.sessionSetups}
            cellClicked={(cellId) => navigate(`/admin/sessionSetup/${cellId}`)}
          />
        </CollapsibleCard>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <CollapsibleCard
          color="warning"
          title="Sessions"
          description="Self-created"
        >
          <p>Last session: {data?._latestSessionCreatedAt}</p>
          <tables.sessions
            data={data?.sessions.sessions}
            cellClicked={(cellId) => navigate(`/admin/session/${cellId}`)}
          />
        </CollapsibleCard>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Organization</h4>
          </CardHeader>
          <CardBody>
            <tables.organizations
              data={[data?.organization]}
              cellClicked={(cellId) =>
                navigate(`/admin/organization/${cellId}`)
              }
            />
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>DANGER ZONE!</h4>
          </CardHeader>
          <CardBody>
            <UserActivation
              userId={params.id}
              deactivated={data?.deactivated}
              refetch={refetch}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
