import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
// data queries
import { useQuery } from '@apollo/client';
import { GET_SESSION_SETUP_BY_ID } from '../../queries/sessionSetup';
import { useNavigate, useParams } from 'react-router-dom';
import tables from 'utils/tables.jsx';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export default function SessionSetupView(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const data = useQuery(GET_SESSION_SETUP_BY_ID, {
    fetchPolicy: 'network-only',
    variables: { sessionSetupId: params.id },
  }).data?._getSessionSetupById;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Info</h4>
          </CardHeader>
          <CardBody>
            <tables.sessionSetups
              data={[data]}
              cellClicked={(cellId) => navigate(`/admin/user/${cellId}`)}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Sessions</h4>
          </CardHeader>
          <CardBody>
            <tables.sessions
              data={data?.sessions.sessions}
              cellClicked={(cellId) => navigate(`/admin/session/${cellId}`)}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
