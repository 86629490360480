import ArrowDropDownCircleRounded from '@mui/icons-material/ArrowDropDownCircleRounded';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import styles from './CollapsibleCard.module.css';
import { useState } from 'react';

export default function CollapsibleCard({
  color,
  title,
  description,
  children,
}) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Card>
      <CardHeader color={color} className={styles.header}>
        <button
          className={styles.collapseBtn}
          onClick={() => setCollapsed((prev) => !prev)}
        >
          <ArrowDropDownCircleRounded
            style={{ rotate: collapsed ? '-90deg' : '' }}
          />
        </button>
        <div>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.description}>{description}</p>
        </div>
      </CardHeader>
      <CardBody>{!collapsed && children}</CardBody>
    </Card>
  );
}
