import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { Button, Switch } from '@material-ui/core';
import { UPDATE_PUBLISHER_INFO } from 'queries/publisher';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  switchLabel: {
    color: 'black',
    width: 'fit-content',
  },
  deletePublisherModal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    translate: '-50% -50%',
    padding: '2rem',
    backgroundColor: 'white',
    borderRadius: '2rem',
  },
  modalTitle: {
    padding: 0,
    margin: 0,
    fontSize: '1.5rem',
    fontWeight: '500',
  },
};

const useStyles = makeStyles(styles);

export const PublisherOptions = ({ publisherId, publisher, refetch }) => {
  const classes = useStyles();

  const [updatePublisherInfo] = useMutation(UPDATE_PUBLISHER_INFO, {
    variables: { publisherId },
    onCompleted: (data) => {
      if (data.updatePublisherInfo) refetch();
    },
  });

  if (!publisher) return null;

  return (
    <div className={classes.root}>
      <Button
        onClick={() =>
          updatePublisherInfo({
            variables: { deactivated: !publisher.deactivated },
          })
        }
        fullWidth
        color={publisher.deactivated ? 'primary' : 'secondary'}
        variant="contained"
      >
        {publisher.deactivated ? 'Activate' : 'Deactivate'} publisher
      </Button>
    </div>
  );
};
