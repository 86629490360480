import { gql } from '@apollo/client';

export const GET_ALL_SESSIONS = gql`
  query getAllSessions($page: Int!, $search: String!) {
    _getAllSessions(page: $page, search: $search) {
      sessions {
        id
        title
        pin
        createdAt
        creator {
          firstName
          lastName
        }
        participantCount
      }
      totalSessions
    }
  }
`;

export const GET_SESSION_BY_ID = gql`
  query getSessionById($sessionId: ID!) {
    _getSessionById(sessionId: $sessionId) {
      id
      pin
      title
      createdAt
      creator {
        id
        firstName
        lastName
      }
      participantCount
    }
  }
`;
