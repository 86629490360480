import { Button, makeStyles } from '@material-ui/core';

const styles = {
  details: {
    fontSize: '1rem',
    border: '1px solid black',
    borderRadius: '0.25rem',
    width: 'max-content',
    padding: '0.25rem 0.5rem',
    margin: 0,
  },
  detailsSummary: {
    cursor: 'pointer',
    width: 'max-content',
  },
};

const useStyles = makeStyles(styles);

export default function ConfirmBtn({ text, onClick }) {
  const classes = useStyles();

  return (
    <details className={classes.details} onClick={(e) => e.stopPropagation()}>
      <summary className={classes.detailsSummary}>{text}</summary>
      <Button color="primary" variant="outlined" onClick={onClick}>
        CONFIRM
      </Button>
    </details>
  );
}
