import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import { APPROVE_USER_EMAIL } from '../../queries/admin';
import useGetApprovedEmails from 'hooks/useGetApprovedEmails';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

function ApproveUserEmail() {
  const classes = useStyles();
  const [userEmail, setUserEmail] = useState('');
  const [invalidFormat, setInvalidFormat] = useState(false);

  const [approvedEmails, refetchApprovedEmails] = useGetApprovedEmails();
  const [approveUserEmail] = useMutation(APPROVE_USER_EMAIL, {
    variables: {
      email: userEmail,
    },
    onCompleted: () => refetchApprovedEmails(),
  });

  useEffect(() => {
    const isValidEmailFormat = (email) => {
      const atMarkContent = email.split('@');
      const validAtMarkContent =
        atMarkContent?.[0]?.length > 0 && atMarkContent?.[1]?.length > 0;
      const dotContent = atMarkContent[1]?.split('.');
      const validDotContent =
        dotContent?.[0]?.length > 0 && dotContent?.[1]?.length > 0;
      return validAtMarkContent && validDotContent;
    };
    // Button will be disabled if this function does not return true
    setInvalidFormat(!isValidEmailFormat(userEmail));
  }, [userEmail]);

  const handleChange = (event) => {
    event.persist();
    setUserEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await approveUserEmail();
  };

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="userEmail"
          label="User email"
          name="userEmail"
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={invalidFormat}
          className={classes.submit}
        >
          Approve new user email
        </Button>
      </form>
      {approvedEmails.length > 0 && (
        <>
          <p>Approved emails:</p>
          <ul>
            {approvedEmails.map((email) => (
              <li key={email}>{email}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}

export default ApproveUserEmail;
