import { AuthContext } from 'context/AuthContext';
import Admin from 'layouts/Admin';
import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes';

const layouts = {
  '/admin': Admin,
};

export default function AppRoutes() {
  const { authenticated } = useContext(AuthContext);

  const filteredRoutes = authenticated
    ? routes
    : routes.filter(({ path }) => path === '/login' || path === '/callback');

  return (
    <Routes>
      {filteredRoutes.map((route, i) => {
        const Layout = layouts[route.layout];
        return (
          <Route
            path={route.layout + route.path}
            element={<Layout>{route.element}</Layout>}
            key={i}
          />
        );
      })}
      {authenticated ? (
        <Route path="/*" element={<Navigate to="/admin/dashboard" replace />} />
      ) : (
        <Route path="/*" element={<Navigate to="/admin/login" replace />} />
      )}
    </Routes>
  );
}
