import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_PUBLISHER,
  REMOVE_USER_FROM_PUBLISHER,
} from '../../queries/publisher';
import { makeStyles } from '@material-ui/core/styles';
import AddUserToPublisher from './AddUserToPublisher';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
// data hooks
import { PublisherOptions } from './PublisherOptions.jsx';
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmBtn from 'components/ConfirmBtn/ConfirmBtn';
import tables from 'utils/tables';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

function PublisherView(props) {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const { data: { getPublisher: data } = {}, refetch } = useQuery(
    GET_PUBLISHER,
    { variables: { publisherId: params.publisherId } }
  );

  const [removeUserFromPublisher] = useMutation(REMOVE_USER_FROM_PUBLISHER, {
    variables: { publisherId: params.publisherId },
    onCompleted: () => refetch(),
  });

  return (
    <>
      <AddUserToPublisher publisherId={params.publisherId} refetch={refetch} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Info</h4>
            </CardHeader>
            <CardBody>
              <PublisherOptions
                publisherId={params.publisherId}
                publisher={data}
                refetch={refetch}
              />
              <tables.publishers data={[data]} />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Users</h4>
            </CardHeader>
            <CardBody>
              <tables.users
                data={data?.users}
                extras={{
                  map: (el) => [
                    <ConfirmBtn
                      text="REMOVE"
                      onClick={() =>
                        removeUserFromPublisher({
                          variables: { userId: el.id },
                        })
                      }
                    />,
                  ],
                }}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <CollapsibleCard
            color="info"
            title="Products"
            description="Self-created"
          >
            <tables.products
              data={data?.products}
              cellClicked={(cellId) => navigate(`/admin/product/${cellId}`)}
            />
          </CollapsibleCard>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default PublisherView;
