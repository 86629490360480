import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_DATA } from '../queries/admin';

export default () => {
  const { data } = useQuery(GET_DASHBOARD_DATA, {
    onError: (e) => {
      console.log(e);
      localStorage.removeItem('authenticated');
      window.location.assign('/login');
    },
  });
  if (data && data._getDashboardData) {
    return data._getDashboardData;
  } else {
    return {
      totalUsers: 0,
      lastWeekUsers: 0,
      totalSessionSetups: 0,
      lastWeekSessionSetups: 0,
      totalParticipants: 0,
      lastWeekParticipants: 0,
      totalSessions: 0,
      lastWeekSessions: 0,
      usersCreatedEachDay: [],
      sessionSetupsCreatedEachDay: [],
      participantsCreatedEachDay: [],
      sessionsCreatedEachDay: [],
    };
  }
};
