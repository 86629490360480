import { gql } from '@apollo/client';

export const GET_ALL_ORGANIZATIONS = gql`
  query getAllOrganizations($page: Int!, $search: String!) {
    _getAllOrganizations(page: $page, search: $search) {
      organizations {
        id
        name
        createdAt
        members {
          totalUsers
          users {
            _latestSessionCreatedAt
          }
        }
        sessionSetups {
          totalSessionSetups
        }
        sessions {
          totalSessions
        }
      }
      totalOrganizations
    }
  }
`;

export const GET_ORGANIZATION_BY_ID = gql`
  query getOrganizationById($orgId: ID!) {
    _getOrganizationById(orgId: $orgId) {
      id
      name
      createdAt
      updatedAt
      googleHostedDomain
      azureADTenantID
      members {
        users {
          id
          firstName
          lastName
          email
          organization {
            id
            name
          }
          sessionSetups {
            totalSessionSetups
          }
          sessions {
            totalSessions
          }
          createdAt
          deactivated
          _latestSessionCreatedAt
        }
        totalUsers
      }
      sessionSetups {
        sessionSetups {
          id
          title
          sessions {
            totalSessions
          }
          creator {
            id
            firstName
            lastName
          }
          createdAt
        }
        totalSessionSetups
      }
      sessions {
        sessions {
          id
          pin
          title
          participantCount
          creator {
            id
            firstName
            lastName
          }
          createdAt
        }
        totalSessions
      }
      _sessionsCreatedEachDay
      _sessionSetupsCreatedEachDay
    }
  }
`;

export const CREATE_NEW_ORGANIZATION = gql`
  mutation createNewOrganization($name: String!) {
    _createNewOrganization(name: $name) {
      id
      name
    }
  }
`;
