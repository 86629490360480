import { useEffect, useState, createContext } from 'react';

export const AuthContext = createContext();

//ref https://medium.com/@ni3t/reacts-usestate-and-context-for-auth-routing-78347da1d6f
// eslint-disable-next-line react/display-name
const AuthProvider = ({ children, ...props }) => {
  const prevAuth = window.localStorage.getItem('authenticated') || false;
  const prevtoken = window.localStorage.getItem('token') || null;
  // console.log('prevAuth', prevAuth, !prevAuth, !!prevAuth)
  const [authenticated, setAuthenticated] = useState(
    props
      ? props.authenticated === undefined
        ? prevAuth
        : props.authenticated
      : prevAuth
  );
  const [token, setToken] = useState(prevtoken);

  useEffect(() => {
    if (authenticated) {
      window.localStorage.setItem('authenticated', authenticated);
      window.localStorage.setItem('token', token);
    } else {
      window.localStorage.removeItem('authenticated');
      window.localStorage.removeItem('token');
    }
  }, [authenticated, token]);

  const defaultContext = {
    authenticated,
    setAuthenticated,
    token,
    setToken,
  };
  return (
    <AuthContext.Provider value={defaultContext}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
