import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

const graphqlPath = '/graphql';

const urls = {
  api: process.env.REACT_APP_API + graphqlPath,
  ws: process.env.REACT_APP_WS + graphqlPath,
};
const httpLink = createHttpLink({
  uri: urls.api,
});

const wsLink = new GraphQLWsLink({
  url: urls.ws,
  lazy: true,
  options: {
    reconnect: true,
    connectionParams: {
      Authentication: localStorage.getItem('token'),
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authentication: token ? `Bearer ${token}` : '',
    },
  };
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: authLink.concat(localStorage.getItem('token') ? link : httpLink),
  cache,
});

export default client;
