import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
// data hooks
import PageSelector from 'components/PageSelector/PageSelector.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import tables from 'utils/tables.jsx';
import { useQuery } from '@apollo/client';
import { GET_ALL_SESSIONS } from 'queries/session.js';
const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export default function SessionList(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const page = Number(params.page ?? 1);
  const search = params.search ?? '';
  const { data: { _getAllSessions: data } = {}, loading } = useQuery(
    GET_ALL_SESSIONS,
    { fetchPolicy: 'cache-first', variables: { page, search } }
  );
  return (
    <>
      <PageSelector
        page={page}
        search={search}
        setPage={({ page, search }) =>
          navigate(`/admin/sessions/${page}/${search}`)
        }
        amount={data?.totalSessions}
        loading={loading}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Session List</h4>
              <p className={classes.cardCategoryWhite}>
                All registered sessions
              </p>
            </CardHeader>
            <CardBody>
              <tables.sessions
                data={data?.sessions}
                cellClicked={(cellId) => navigate(`/admin/session/${cellId}`)}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <PageSelector
        page={page}
        search={search}
        setPage={({ page, search }) =>
          navigate(`/admin/sessions/${page}/${search}`)
        }
        amount={data?.totalSessions}
        loading={loading}
      />
    </>
  );
}
