import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION_BY_ID } from '../../queries/organization';
import { makeStyles } from '@material-ui/core/styles';
import AddUserToOrganization from './AddUserToOrganization';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
import IconButton from '@material-ui/core/IconButton';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// data hooks
import { OrganizationOptions } from './OrganizationOptions.js';
import DateChart from 'components/DateChart/DateChart';
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard';
import { useNavigate, useParams } from 'react-router-dom';
import tables from 'utils/tables';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

function OrganizationView(props) {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [showOrganizationOptions, setShowOrganizationOptions] = useState(false);
  const { data: { _getOrganizationById: data } = {}, refetch } = useQuery(
    GET_ORGANIZATION_BY_ID,
    { variables: { orgId: params.orgId } }
  );

  const toggleOrganizationOptions = () => {
    setShowOrganizationOptions((previous) => !previous);
  };

  const buttonColor = showOrganizationOptions ? 'primary' : 'default';

  return (
    <>
      <AddUserToOrganization
        orgId={params.orgId}
        refetchOrganization={refetch}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Info</h4>
              <IconButton
                color={buttonColor}
                size="small"
                onClick={toggleOrganizationOptions}
              >
                <SettingsApplicationsIcon />
              </IconButton>
            </CardHeader>
            <CardBody>
              {showOrganizationOptions ? (
                <OrganizationOptions
                  orgId={params.orgId}
                  hostedDomain={data.googleHostedDomain}
                  tenantID={data.azureADTenantID}
                  refetch={refetch}
                />
              ) : null}
              <tables.organizations data={[data]} />
            </CardBody>
          </Card>
        </GridItem>
        <DateChart
          title="sessions by organization"
          data={data?._sessionsCreatedEachDay}
        />
        <DateChart
          title="setups by organization"
          data={data?._sessionSetupsCreatedEachDay}
        />
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Members</h4>
            </CardHeader>
            <CardBody>
              <tables.users
                data={data?.members?.users}
                cellClicked={(cellId) => navigate(`/admin/user/${cellId}`)}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <CollapsibleCard
            color="info"
            title="Session Setups"
            description="Self-created"
          >
            <tables.sessionSetups
              data={data?.sessionSetups?.sessionSetups}
              cellClicked={(cellId) =>
                navigate(`/admin/sessionSetup/${cellId}`)
              }
            />
          </CollapsibleCard>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <CollapsibleCard
            color="warning"
            title="Sessions"
            description="Self-created"
          >
            <tables.sessions
              data={data?.sessions?.sessions}
              cellClicked={(cellId) => navigate(`/admin/session/${cellId}`)}
            />
          </CollapsibleCard>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default OrganizationView;
