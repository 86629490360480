import { format } from 'date-fns';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import AccessTime from '@mui/icons-material/AccessTime';
import { Bar } from 'react-chartjs-2';
import {
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  TimeScale,
  Tooltip,
} from 'chart.js';
import { makeStyles } from '@material-ui/core';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
Chart.register(CategoryScale, LinearScale, BarElement, TimeScale, Tooltip);

const useStyles = makeStyles(styles);

export default function DateChart({ title, data }) {
  const titleCased = title[0].toUpperCase() + title.substring(1).toLowerCase();
  const titleDecased = title.toLowerCase();

  const classes = useStyles();

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card chart>
        <CardBody>
          <h4 className={classes.cardTitle}>{titleCased}</h4>
          <p className={classes.cardCategory}>
            Metrics for recently created {titleDecased}
          </p>
          <Bar
            height={50}
            options={{
              backgroundColor: Array(29).fill('darkgray').concat('limegreen'),
              borderRadius: 2,
              scales: { y: { ticks: { precision: 0 } } },
            }}
            data={{
              labels: Array(30)
                .fill(Date.now())
                .map((now, i) => format(now - i * 1000 * 86400, 'dd.MM.yyyy'))
                .reverse(),
              datasets: [{ label: `New ${titleDecased}`, data }],
            }}
          />
        </CardBody>
        <CardFooter chart>
          <div className={classes.stats}>
            <AccessTime /> Last 30 days
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  );
}
