import { useQuery } from '@apollo/client';
import { GET_APPROVED_EMAILS } from '../queries/admin';
export default () => {
  const { data, refetch } = useQuery(GET_APPROVED_EMAILS, {
    fetchPolicy: 'cache-and-network',
  });

  if (data && data._getApprovedEmails) {
    return [data._getApprovedEmails, refetch];
  } else {
    return [[], refetch];
  }
};
