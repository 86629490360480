import { gql } from '@apollo/client';

export const GET_ADMIN_LOGIN_URL = gql`
  query getAdminLoginUrl {
    _getAdminLoginUrl
  }
`;

export const AUTHENTICATE_ADMIN = gql`
  mutation authenticateAdmin($code: String!) {
    _authenticateAdmin(code: $code)
  }
`;
