import React from 'react';
import { useMutation } from '@apollo/client';
import { DEACTIVATE_USER, ACTIVATE_USER } from '../../queries/user';
import Button from '@material-ui/core/Button';

export const UserActivation = ({ userId, deactivated, refetch }) => {
  const [deactivateUser] = useMutation(DEACTIVATE_USER, {
    variables: {
      userId,
    },
    onCompleted: (data) => {
      if (data?._deactivateUser?.deactivated === true) {
        refetch();
      }
    },
  });
  const [activateUser] = useMutation(ACTIVATE_USER, {
    variables: {
      userId,
    },
    onCompleted: (data) => {
      if (data?._activateUser?.deactivated === false) {
        refetch();
      }
    },
  });
  const handleDeactivate = () => {
    deactivateUser();
  };
  const handleActivate = () => {
    activateUser();
  };

  return (
    <Button
      onClick={deactivated ? handleActivate : handleDeactivate}
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
    >
      {deactivated ? 'Activate User' : 'Deactivate User'}
    </Button>
  );
};
