import { gql } from '@apollo/client';

export const GET_PUBLISHER = gql`
  query getPublisher($publisherId: ID!) {
    getPublisher(publisherId: $publisherId) {
      id
      name
      products {
        id
        title
        shared
        createdAt
        purchases {
          id
        }
        sessionSetups {
          id
        }
      }
      users {
        id
        firstName
        lastName
        email
        organization {
          id
          name
        }
        createdAt
        publishers {
          id
        }
        sessions {
          totalSessions
        }
        sessionSetups {
          totalSessionSetups
        }
        _latestSessionCreatedAt
      }
      deactivated
    }
  }
`;

export const GET_ALL_PUBLISHERS = gql`
  query getAllPublishers($page: Int!, $search: String!) {
    _getAllPublishers(page: $page, search: $search) {
      publishers {
        id
        name
        createdAt
        products {
          id
        }
        users {
          id
        }
        deactivated
      }
      totalPublishers
    }
  }
`;

export const UPDATE_PUBLISHER_INFO = gql`
  mutation updatePublisherInfo($publisherId: ID!, $deactivated: Boolean) {
    updatePublisherInfo(publisherId: $publisherId, deactivated: $deactivated)
  }
`;

export const ADD_USER_TO_PUBLISHER = gql`
  mutation addUserToPublisher($publisherId: ID!, $email: String!) {
    _addUserToPublisher(publisherId: $publisherId, email: $email)
  }
`;

export const REMOVE_USER_FROM_PUBLISHER = gql`
  mutation removeUserFromPublisher($publisherId: ID!, $userId: ID!) {
    _removeUserFromPublisher(publisherId: $publisherId, userId: $userId)
  }
`;
