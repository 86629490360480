import { gql } from '@apollo/client';

export const productPurchaseInfo = `
  id
  product {
    id
    title
    shared
    createdAt
    purchases {
      id
    }
    sessionSetups {
      id
      title
      createdAt
      creator {
        id
        firstName
        lastName
      }
      sessions {
        totalSessions
      }
    }
  }
  revokedAt
  createdAt
  price {
    currency
    amount
  }
  recurringDays
  timesPaid
  duePayments
  pending
  user {
    id
    firstName
    lastName
  }
  organization {
    id
    name
    createdAt
    members {
      users {
        id
        _latestSessionCreatedAt
      }
      totalUsers
    }
    sessionSetups {
      totalSessionSetups
    }
    sessions {
      totalSessions
    }
  }
  comment
`;

export const GET_PURCHASE = gql`
  query getPurchase($purchaseId: ID!) {
    _getPurchase(purchaseId: $purchaseId) {
      id
      ... on ProductPurchase {
        ${productPurchaseInfo}
      }
    }
  }
`;

export const GET_ALL_PRODUCT_PURCHASES = gql`
  query getAllProductPurchases($page: Int!, $search: String!, $pending: Boolean!) {
    _getAllProductPurchases(page: $page, search: $search, pending: $pending) {
      totalPurchases
      purchases {
        ${productPurchaseInfo}
      }
    }
  }
`;

export const VERIFY_PURCHASE = gql`
  mutation verifyPurchase($purchaseId: ID!) {
    _verifyPurchase(purchaseId: $purchaseId)
  }
`;

export const REVOKE_PURCHASE = gql`
  mutation revokePurchase($purchaseId: ID!) {
    _revokePurchase(purchaseId: $purchaseId)
  }
`;
