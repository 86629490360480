import styles from './PageSelector.module.css';

export default function PageSelector({
  page,
  search,
  setPage,
  amount,
  loading,
}) {
  return (
    <nav className={styles.pageSelectorRoot}>
      <div className={styles.pageContainer}>
        <button
          onClick={() => setPage({ page: page - 1, search })}
          disabled={page === 1}
          className={styles.pageButton}
        >
          Previous Page
        </button>
        <p className={styles.pageCount}>{page}</p>
        <button
          disabled={amount != 24 && !loading}
          onClick={() => setPage({ page: page + 1, search })}
          className={styles.pageButton}
        >
          Next Page
        </button>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          setPage({ page: 1, search: e.target.elements.search.value });
        }}
        className={styles.searchForm}
      >
        <input
          name="search"
          placeholder="Search query..."
          defaultValue={search}
          className={styles.searchBox}
        />
        <button className={styles.searchButton}>Search</button>
      </form>
    </nav>
  );
}
