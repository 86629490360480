import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
// data hooks
import PageSelector from 'components/PageSelector/PageSelector.jsx';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import tables from 'utils/tables.jsx';
import { useMutation, useQuery } from '@apollo/client';
import ConfirmBtn from 'components/ConfirmBtn/ConfirmBtn.jsx';
import {
  GET_ALL_PRODUCT_PURCHASES,
  REVOKE_PURCHASE,
  VERIFY_PURCHASE,
} from 'queries/purchase.js';
import { Switch } from '@mui/material';
const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export default function ProductPurchaseList(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(params.page ?? 1);
  const search = params.search ?? '';
  const pending = searchParams.get('pending') === 'true';
  const {
    data: { _getAllProductPurchases: data } = {},
    loading,
    refetch,
  } = useQuery(GET_ALL_PRODUCT_PURCHASES, {
    fetchPolicy: 'cache-first',
    variables: { page, search, pending },
  });

  const [verifyPurchase] = useMutation(VERIFY_PURCHASE, {
    onCompleted: () => refetch(),
  });
  const [revokePurchase] = useMutation(REVOKE_PURCHASE, {
    onCompleted: () => refetch(),
  });

  return (
    <>
      <PageSelector
        page={page}
        search={search}
        setPage={({ page, search }) =>
          navigate(`/admin/product-purchases/${page}/${search}`)
        }
        amount={data?.totalPurchases}
        loading={loading}
      />
      <GridItem xs={12} sm={12} md={12}>
        <label>
          Pending only
          <Switch
            checked={pending}
            color="info"
            onChange={(e) => setSearchParams({ pending: !pending })}
          />
        </label>
      </GridItem>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Product Purchases List</h4>
              <p className={classes.cardCategoryWhite}>All product purchases</p>
            </CardHeader>
            <CardBody>
              <tables.purchases
                data={data?.purchases}
                extras={{
                  map: (el) => [
                    el.pending && !el.revokedAt && (
                      <ConfirmBtn
                        text="VERIFY"
                        onClick={() =>
                          verifyPurchase({ variables: { purchaseId: el.id } })
                        }
                      />
                    ),
                    !el.revokedAt && (
                      <ConfirmBtn
                        text="REVOKE"
                        onClick={() =>
                          revokePurchase({ variables: { purchaseId: el.id } })
                        }
                      />
                    ),
                  ],
                }}
                cellClicked={(cellId) => navigate(`/admin/purchase/${cellId}`)}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <PageSelector
        page={page}
        search={search}
        setPage={({ page, search }) =>
          navigate(`/admin/product-purchases/${page}/${search}`)
        }
        amount={data?.totalPurchases}
        loading={loading}
      />
    </>
  );
}
