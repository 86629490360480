import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PRODUCT } from '../../queries/product';
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardBody from '../../components/Card/CardBody.js';
// data hooks
import CollapsibleCard from 'components/CollapsibleCard/CollapsibleCard';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmBtn from 'components/ConfirmBtn/ConfirmBtn';
import tables from 'utils/tables';
import { REVOKE_PURCHASE, VERIFY_PURCHASE } from 'queries/purchase';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    'color': '#FFFFFF',
    'marginTop': '0px',
    'minHeight': 'auto',
    'fontWeight': '300',
    'fontFamily': "'Roboto', 'Helvetica', 'Arial', sans-serif",
    'marginBottom': '3px',
    'textDecoration': 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

export default function ProductView(props) {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: { getProduct: data } = {},
    refetch,
    loading,
  } = useQuery(GET_PRODUCT, {
    variables: { productId: params.productId },
  });

  const [verifyPurchase] = useMutation(VERIFY_PURCHASE, {
    onCompleted: () => refetch(),
  });
  const [revokePurchase] = useMutation(REVOKE_PURCHASE, {
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    if (!loading && !data) navigate('/admin/products');
  }, [data]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Info</h4>
          </CardHeader>
          <CardBody>
            <tables.products data={[data]} />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Purchases</h4>
          </CardHeader>
          <CardBody>
            <tables.purchases
              data={data?.purchases}
              cellClicked={(id) => navigate(`/admin/purchase/${id}`)}
              extras={{
                map: (el) => [
                  el.pending && (
                    <ConfirmBtn
                      text="VERIFY"
                      onClick={() =>
                        verifyPurchase({ variables: { purchaseId: el.id } })
                      }
                    />
                  ),
                  <ConfirmBtn
                    text="REVOKE"
                    onClick={() =>
                      revokePurchase({ variables: { purchaseId: el.id } })
                    }
                  />,
                ],
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose">
            <h4 className={classes.cardTitleWhite}>Revoked Purchases</h4>
          </CardHeader>
          <CardBody>
            <tables.purchases
              data={data?.revokedPurchases}
              cellClicked={(id) => navigate(`/admin/purchase/${id}`)}
            />
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <CollapsibleCard
          color="info"
          title="Session Setups"
          description="Self-created"
        >
          <tables.sessionSetups data={data?.sessionSetups} />
        </CollapsibleCard>
      </GridItem>
    </GridContainer>
  );
}
