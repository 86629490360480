/* eslint-disable react/no-multi-comp */
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { GET_ADMIN_LOGIN_URL } from 'queries/authentication';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://voxt.no/">
        VOXT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();

  const { data, loading } = useQuery(GET_ADMIN_LOGIN_URL, {
    fetchPolicy: 'network-only',
  });

  const redirectUri =
    process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_ADMIN;

  const loginWithRedirect = () => {
    const loginUrl =
      data._getAdminLoginUrl +
      '&response_type=code' +
      `&redirect_uri=${redirectUri}/admin/callback`;
    window.location.href = loginUrl;
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
            value="signin"
            onClick={loginWithRedirect}
          >
            Sign In
          </Button>
          <Copyright />
        </div>
      </Grid>
    </Grid>
  );
}
