import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './context/AuthContext';
// core components
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apollo';

import './assets/css/material-dashboard-react.css?v=1.8.0';
import AppRoutes from 'AppRoutes';

function App() {
  return (
    <AuthProvider>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;

/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
