/* eslint-disable react/no-multi-comp */
import { useEffect, useContext } from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useMutation } from '@apollo/client';
import { AuthContext } from '../../context/AuthContext';
import { AUTHENTICATE_ADMIN } from 'queries/authentication';
import { useNavigate } from 'react-router-dom';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://voxt.no/">
        VOXT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignInCallback() {
  const navigate = useNavigate();
  const code = new URLSearchParams(window.location.search).get('code');
  const [authenticateAdmin] = useMutation(AUTHENTICATE_ADMIN);
  const { setAuthenticated, setToken } = useContext(AuthContext);

  useEffect(() => {
    const callAuthentication = async () => {
      try {
        const { data } = await authenticateAdmin({
          variables: { code },
        });
        // Authentication successful! Grab the token.
        const token = data._authenticateAdmin;
        setAuthenticated(true);
        setToken(token);
        navigate('/');
      } catch (error) {
        console.error(error);
      }
    };
    callAuthentication();
  }, []);

  return <Copyright />;
}
